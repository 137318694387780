import {
  Middleware,
  StoreEnhancer,
  applyMiddleware,
  compose,
  createStore,
} from "redux";
import { thunk } from "redux-thunk";
import createRootReducer from "./reducers";

export default () => {
  const enhancers: StoreEnhancer<{}, {}>[] = [];
  const middlewares: Middleware<{}, {}>[] = [thunk];

  const composedEnhancers: StoreEnhancer<{}, {}> = compose(
    applyMiddleware(...middlewares),
    ...enhancers,
  );

  const initialState = {};

  const store = createStore(
    createRootReducer(),
    initialState,
    composedEnhancers,
  );

  return { store };
};
