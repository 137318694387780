import { UAContext } from "@quentin-sommer/react-useragent";
import { useNavigate } from "@remix-run/react";
import classnames from "classnames";
import { motion } from "framer-motion";
import httpStatus from "http-status";
import lodash, { max } from "lodash";
import {
  ChevronDownCircle,
  ChevronUpCircle,
  CircleCheck,
  SatelliteDish,
} from "lucide-react";
import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Sheet } from "react-modal-sheet";
import { useDispatch } from "react-redux";
import Modal from "~/components/Modal";
import { setModalParams } from "~/containers/Modal";
import renderDigits from "~/helpers/renderDigits";
import toastWithStyle from "~/helpers/toastWithStyle";
import { postPurchaseService } from "~/services/api";
import ceilify from "~/utils/ceilify";
import to from "~/utils/to";

function AutoOrderConfirmation({
  visible,
  onClose,
  cookie,
  user,
  service,
  params,
  providers,
}: {
  visible: boolean;
  onClose: () => void;
  cookie: any;
  service: any;
  params: any;
}) {
  const [submitting, setSubmitting] = React.useState(false);
  const [minPrice, setMinPrice] = React.useState(0);
  const [maxPrice, setMaxPrice] = React.useState(cookie?.user?.balance || 2);
  const [readMoreIsExpanded, setReadMoreIsExpanded] = React.useState(false);
  const [selectedProvider, setSelectedProvider] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uaResults } = React.useContext(UAContext);
  const { t, i18n } = useTranslation();
  const insufficientBalance = cookie?.user?.balance < maxPrice;

  const onSubmit = async () => {
    if (user.risk > 7) return null;
    setSubmitting(true);

    const [err] = await to(
      postPurchaseService({
        _serviceId: service._id,
        data: {
          minPrice,
          maxPrice,
          _providerId: selectedProvider._id,
        },
        token: cookie.token,
        headers: { "axios-to-fetch": 0 },
      }),
    );

    if (err) {
      setSubmitting(false);
      if (err.response?.status === httpStatus["NOT_ACCEPTABLE"]) {
        return toastWithStyle({
          message: t("modals.autoOrderConfirmation.noProductFoundForThisRange"),
          options: { type: "error" },
          uaResults,
          i18n,
        });
      }
    }

    setSubmitting(false);
    dispatch(
      setModalParams({
        target: "",
      }),
    );
    navigate("/operations");
  };

  useEffect(() => {
    if (providers?.results.length > 0) {
      setSelectedProvider(
        lodash.find(providers?.results, (p) => p.name.common === "p1"),
      );
    }
  }, [providers?.results]);

  const Content = () => {
    if (!cookie || !service || !params || !user) return null;
    return (
      <div
        className="relative flex-col gap-4 rounded-2xl border-0 bg-base-100 pt-4"
        style={{
          paddingBottom: "calc( env(safe-area-inset-bottom) + 1rem )",
        }}
      >
        {/* <div className="mb-maxPrice flex justify-center border-b border-base-content border-opacity-10 px-4 pb-4">
          <div className="uppercase font-semibold">
            {t("modals.orderConfirmation.orderNumber")}
          </div>
        </div> */}
        <div
          className="flex w-full flex-col items-center justify-center gap-4 rounded-xl px-4 text-center"
          dir={i18n.dir()}
        >
          <div className="relative mx-auto flex items-center justify-center md:mt-3 gap-4">
            <div
              style={{
                backgroundImage: `url(https://s3.hidsim.com/services/${service.name.a2}.svg)`,
              }}
              className="h-16 w-16 rounded-xl bg-center bg-cover"
            />
            <div className="flex">
              <div className="h-8 w-8 rounded-full image-full bg-center bg-cover bg-no-repeat border fi-ir" />
              <div className="h-8 w-8 -ms-3 rounded-full image-full bg-center bg-cover bg-no-repeat border fi-in" />
              <div className="h-8 w-8 -ms-3 rounded-full image-full bg-center bg-cover bg-no-repeat border fi-jp" />
              <div className="h-8 w-8 -ms-3 rounded-full image-full bg-center bg-cover bg-no-repeat border fi-us" />
            </div>
          </div>
          <div>
            <h2 className="font-bold">{service?.name.common}</h2>
          </div>

          <div className="flex items-center justify-center text-sm mb-5">
            {!insufficientBalance && (
              <motion.p
                initial="closed"
                animate={readMoreIsExpanded ? "open" : "closed"}
                exit="closed"
                variants={{
                  closed: {
                    height: 80,
                    opacity: 1,
                  },
                  open: {
                    height: "auto",
                    opacity: 1,
                  },
                }}
                transition={{ duration: 1 }}
                className="text-center overflow-hidden relative"
              >
                {t("modals.autoOrderConfirmation.refundPolicy")}
                {/* {!readMoreIsExpanded && (
                  <button
                    className="absolute left-0 bottom-0 bg-base-100 text-blue-500 ps-1"
                    onClick={() => setReadMoreIsExpanded(true)}
                  >
                    {" ..."}
                    {t("readMore")}
                  </button>
                )} */}
              </motion.p>
            )}
            {insufficientBalance && (
              <span className="text-error">
                {t("modals.orderConfirmation.notEnoughCredits", {
                  amount: ceilify(maxPrice - user.balance),
                })}
              </span>
            )}
          </div>

          <div className="flex flex-col w-full gap-3 p-4 rounded-xl bg-base-200">
            <div className="flex justify-between w-full">
              <div>{t("modals.autoOrderConfirmation.upperLimit")}</div>
              <div className="flex justify-between gap-2 min-w-32">
                <button
                  className="disabled:opacity-50"
                  onClick={() => setMaxPrice(maxPrice - 1)}
                  disabled={maxPrice === minPrice + 1 || maxPrice === 1}
                >
                  <ChevronDownCircle className="h-6 w-6" />
                </button>
                <span className="font-bold">
                  {renderDigits({
                    data: maxPrice,
                    i18n,
                  })}
                  <span className="text-xs ms-1 text-neutral-500">
                    {t("coins")}
                  </span>
                </span>
                <button
                  className="disabled:opacity-50"
                  onClick={() => setMaxPrice(maxPrice + 1)}
                >
                  <ChevronUpCircle className="h-6 w-6" />
                </button>
              </div>
            </div>
            <div className="border-b"></div>
            <div className="flex justify-between w-full">
              <div>{t("modals.autoOrderConfirmation.lowerLimit")}</div>
              <div className="flex justify-between gap-2 min-w-32">
                <button
                  className="disabled:opacity-50"
                  disabled={minPrice === 0}
                >
                  <ChevronDownCircle
                    className="h-6 w-6"
                    onClick={() => setMinPrice(minPrice - 1)}
                  />
                </button>
                <span className="font-bold">
                  {renderDigits({
                    data: minPrice,
                    i18n,
                  })}
                  <span className="text-xs ms-1 text-neutral-500">
                    {t("coins")}
                  </span>
                </span>
                <button
                  className="disabled:opacity-50"
                  onClick={() => setMinPrice(minPrice + 1)}
                  disabled={minPrice === maxPrice - 1}
                >
                  <ChevronUpCircle className="h-6 w-6" />
                </button>
              </div>
            </div>
          </div>

          <section className="flex flex-col bg-base-200 rounded-xl w-full">
            {providers?.results.map((p, i) => (
              <li
                className="ps-4 justify-start items-center gap-4 flex"
                key={p._id}
                onClick={(e) => {
                  setSelectedProvider(p);
                }}
              >
                <div>
                  <SatelliteDish className="h-6 w-6 text-neutral-500" />
                </div>
                <div className="flex justify-between items-center grow border-b border-base-200/70 py-4 pe-4">
                  <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 flex">
                    <div className="text-neutral-900 text-sm font-medium font-['Figtree', 'IRANYekanXVF'] leading-normal">
                      {t(`providers.${p.name.common.toLowerCase()}`)}
                    </div>
                  </div>

                  <div className="flex items-center gap-2 text-zinc-400 text-sm font-medium font-['Figtree', 'IRANYekanXVF'] leading-normal">
                    {selectedProvider?.id === p.id && (
                      <CircleCheck size={18} className="text-success" />
                    )}
                  </div>
                </div>
              </li>
            ))}
          </section>
          <div className="w-full">
            {!insufficientBalance && (
              <button
                onClick={onSubmit}
                disabled={
                  submitting ||
                  !selectedProvider ||
                  minPrice > maxPrice ||
                  maxPrice === 0 ||
                  maxPrice === minPrice
                }
                className={classnames(
                  "btn btn-block justify-center text-center rounded-lg",
                  {
                    disabled: submitting,
                  },
                )}
              >
                {!submitting && (
                  <Trans
                    t={t}
                    i18n={i18n}
                    i18nKey="modals.orderConfirmation.orderFor"
                    className="font-bold text-base-content uppercase w-full"
                    components={[
                      <span key="0">Order For</span>,
                      <span key="1" className="font-mono text-lg">
                        At Least {maxPrice}
                      </span>,
                      <span key="2">Credit</span>,
                    ]}
                    values={{ amount: maxPrice }}
                  />
                )}

                {submitting && (
                  <>
                    <span className="font-bold">
                      {t("modals.orderConfirmation.pleaseWait")}
                    </span>
                    <span className="loading loading-spinner loading-md" />
                  </>
                )}
              </button>
            )}

            {insufficientBalance && (
              <button
                onClick={async () => {
                  await dispatch(setModalParams({ target: "" }));
                  navigate("/profile", { state: { modal: "charge" } });
                }}
                className={classnames(
                  "btn btn-block justify-center text-center",
                  { disabled: submitting },
                )}
              >
                <span className="font-bold text-base-content">
                  {t("modals.orderConfirmation.addCredits")}
                </span>
              </button>
            )}
            <p className="text-xs text-zinc-500 mt-1">
              {t("modals.autoOrderConfirmation.note")}
            </p>
          </div>
        </div>
      </div>
    );
  };

  if (uaResults.mobile) {
    return (
      <Sheet
        isOpen={visible}
        onClose={onClose}
        detent="content-height"
        tweenConfig={{ ease: "easeIn", duration: 0.3 }}
        style={{ zIndex: 50 }}
      >
        <Sheet.Container className="!rounded-t-3xl !bg-base-100">
          <Sheet.Header />
          <Sheet.Scroller>
            <Sheet.Content>
              <Content />
            </Sheet.Content>
          </Sheet.Scroller>
        </Sheet.Container>
        <Sheet.Backdrop className="backdrop-blur-sm" onTap={onClose} />
      </Sheet>
    );
  }
  return (
    <Modal
      isOpen={visible}
      onClose={onClose}
      classNames={{
        dialog: "max-w-md",
      }}
    >
      <Content />
    </Modal>
  );
}

export default AutoOrderConfirmation;
