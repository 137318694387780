import { UAContext } from "@quentin-sommer/react-useragent";
import { useNavigate } from "@remix-run/react";
import classnames from "classnames";
import httpStatus from "http-status";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Sheet } from "react-modal-sheet";
import { useDispatch } from "react-redux";
import CloseIcon from "~/assets/svg/Close";
import Modal from "~/components/Modal";
import { setModalParams } from "~/containers/Modal";
import { postPurchaseProduct } from "~/services/api";
import ceilify from "~/utils/ceilify";
import to from "~/utils/to";

function OrderConfirmationModal({
  visible,
  onClose,
  cookie,
  user,
  product,
  params,
}: {
  visible: boolean;
  onClose: () => void;
  cookie: any;
  product: any;
  params: any;
}) {
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);
  const insufficientBalance = cookie?.user?.balance < product?.price;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uaResults } = React.useContext(UAContext);
  const { t, i18n } = useTranslation();

  const onSubmit = async () => {
    if (user.risk > 7) return null;
    setError(null);
    setSubmitting(true);

    const [err] = await to(
      postPurchaseProduct({
        token: cookie.token,
        _productId: product._id,
      }),
    );

    if (err) {
      if (err.response?.data?.data?.label === "no_number") {
        return dispatch(
          setModalParams({
            target: "retryingOrder",
            props: { cookie, params },
          }),
        );
      }
      setSubmitting(false);
      return setError(err);
    }

    setError(null);
    setSubmitting(false);
    dispatch(setModalParams({ target: "" }));
    navigate("/operations");
  };

  const Content = () => {
    if (!cookie || !product || !params || !user) return null;
    return (
      <div
        className="relative flex-col gap-4 border-0 bg-base-100 pt-4"
        style={{
          paddingBottom: "calc( env(safe-area-inset-bottom) + 1rem )",
        }}
      >
        <div className="mb-5 flex justify-center border-b border-base-content border-opacity-10 px-4 pb-4">
          <div className="uppercase font-semibold">
            {t("modals.orderConfirmation.orderNumber")}
          </div>
        </div>
        <div
          className="flex w-full flex-col items-center justify-center gap-5 rounded-xl px-4 text-center"
          dir={i18n.dir()}
        >
          <div className="relative mx-auto flex items-center justify-center">
            <div
              style={{
                backgroundImage: `url(https://s3.hidsim.com/services/${product?._service.name.a2}.svg)`,
              }}
              className="h-16 w-16 rounded-xl bg-center bg-cover"
            />
            <div
              className={`absolute -bottom-3 -right-3 h-8 w-8 rounded-full bg-center bg-cover bg-no-repeat fi-${product?._country.name.a2?.toLowerCase()}`}
            />
          </div>
          <div>
            <h2 className="font-bold">{product?._service?.name.common}</h2>
            <h3>{product?._country.name.common}</h3>
          </div>

          <div className="flex items-center justify-center text-sm">
            {!insufficientBalance && (
              <p className="text-center">
                {t("modals.orderConfirmation.refundPolicy")}
              </p>
            )}
            {insufficientBalance && (
              <span className="text-error">
                {t("modals.orderConfirmation.notEnoughCredits", {
                  amount: ceilify(product.price - user.balance),
                })}
              </span>
            )}
          </div>
          <div className="w-full">
            {!insufficientBalance && (
              <button
                onClick={onSubmit}
                disabled={submitting}
                className={classnames(
                  "btn btn-block justify-center text-center rounded-lg",
                  { disabled: submitting },
                )}
              >
                {!submitting && (
                  <Trans
                    t={t}
                    i18n={i18n}
                    i18nKey="modals.orderConfirmation.orderFor"
                    className="font-bold text-base-content uppercase w-full"
                    components={[
                      <span key="0">Order For</span>,
                      <span key="1" className="font-mono text-lg">
                        {product.price}
                      </span>,
                      <span key="2">Credit</span>,
                    ]}
                    values={{ amount: product.price }}
                  />
                )}

                {submitting && (
                  <>
                    <span className="font-bold">
                      {t("modals.orderConfirmation.pleaseWait")}
                    </span>
                    <span className="loading loading-spinner loading-md" />
                  </>
                )}
              </button>
            )}

            {insufficientBalance && (
              <button
                onClick={async () => {
                  await dispatch(setModalParams({ target: "" }));
                  navigate("/profile", { state: { modal: "charge" } });
                }}
                className={classnames(
                  "btn btn-block justify-center text-center",
                  { disabled: submitting },
                )}
              >
                <span className="font-bold text-base-content">
                  {t("modals.orderConfirmation.addCredits")}
                </span>
              </button>
            )}

            {error && error?.response?.status === httpStatus["BAD_REQUEST"] && (
              <div className="flex items-center justify-center gap-3 p-3 text-error">
                <CloseIcon className="h-16 w-16" />
                <p className="text-start">
                  Maximum pending order limit reached. Please cancel some
                  existing pending order before creating a new one.
                </p>
              </div>
            )}
            {error && error?.response?.status !== httpStatus["BAD_REQUEST"] && (
              <div className="flex items-center justify-center gap-3 p-3 text-error">
                <CloseIcon className="h-16 w-16" />
                <p className="text-start">
                  Error Code {error.response.status} -{" "}
                  {error.response.data.data?.label} occurred while processing
                  your request. Please try again later.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  if (uaResults.mobile) {
    return (
      <Sheet
        isOpen={visible}
        onClose={onClose}
        detent="content-height"
        tweenConfig={{ ease: "easeIn", duration: 0.3 }}
        disableScrollLocking
      >
        <Sheet.Container className="!rounded-t-3xl !bg-base-100">
          <Sheet.Header />
          <Sheet.Scroller>
            <Sheet.Content>
              <Content />
            </Sheet.Content>
          </Sheet.Scroller>
        </Sheet.Container>
        <Sheet.Backdrop className="backdrop-blur-sm" onTap={onClose} />
      </Sheet>
    );
  }
  return (
    <Modal
      isOpen={visible}
      onClose={onClose}
      classNames={{
        dialog: "max-w-md",
      }}
    >
      <Content />
    </Modal>
  );
}

export default OrderConfirmationModal;
