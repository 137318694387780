import { HIDE_MODAL, SET_MODAL_PARAMS } from "./actions";

const initialState = {
  props: null,
  visible: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL_PARAMS:
      return {
        props: action.props,
        visible: action.target,
      };

    case HIDE_MODAL:
      return { ...state, visible: initialState.visible };
    default:
      return state;
  }
};
