import { UAContext } from "@quentin-sommer/react-useragent";
import classnames from "classnames";
import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Sheet } from "react-modal-sheet";
import Modal from "~/components/Modal";
import to from "~/utils/to";

function cancelOrderConfirmation({
  visible,
  onClose,
  onSubmit,
  operation,
}: {
  visible: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  operation: any;
}) {
  const [submitting, setSubmitting] = React.useState(false);
  const { uaResults } = React.useContext(UAContext);
  const { t } = useTranslation();

  const Content = () => {
    return (
      <div
        className={cx(
          "no-scrollbar relative border-0 bg-base-100 overflow-y-auto",
        )}
        style={{
          paddingBottom: "calc(env(safe-area-inset-bottom) + 2rem)",
        }}
      >
        <div className="mb-5 flex flex-col justify-center items-center gap-4 px-4 pb-4 md:mt-6">
          <div className="font-semibold font-['Hubot-Sans', 'IRANYekanXVF'] text-xl">
            {t("modals.cancelOrderConfirmation.refundNumber")}
          </div>
          <div className="text-center text-neutral-500 text-sm font-normal font-['Figtree', 'IRANYekanXVF'] leading-normal">
            Are you sure you want to refund this number? The credits will be
            back to your wallet immediately.
          </div>
        </div>

        <div className="flex w-full flex-col items-center justify-center gap-7 rounded-xl px-4 text-center">
          <div className="h-16 p-4 rounded-lg border border-neutral-200 border-dashed justify-center items-center gap-2 inline-flex w-full mx-4">
            <div className="justify-start items-center flex">
              <div
                style={{
                  backgroundImage: `url(https://s3.hidsim.com/services/${operation?._service.name.a2}.svg)`,
                }}
                className="h-8 w-8 rounded-full bg-center bg-cover"
              />
              <div
                className={`-ms-3 h-8 w-8 rounded-full bg-center bg-cover bg-no-repeat fi-${operation?._country.name.a2?.toLowerCase()}`}
              />
            </div>
            <div className="text-neutral-900 text-sm font-semibold font-['Hubot-Sans'] leading-normal">
              +{operation.number}
            </div>
          </div>

          <button
            onClick={async () => {
              setSubmitting(true);
              const [] = await to(onSubmit());
              onClose();
              setSubmitting(false);
            }}
            disabled={submitting}
            className={classnames(
              "btn btn-block text-red-600 bg-red-100 text-base font-semibold font-['Figtree', 'IRANYekanXVF'] leading-normal justify-center text-center rounded-full",
            )}
          >
            {!submitting && (
              <span>{t("modals.cancelOrderConfirmation.refund")}</span>
            )}

            {submitting && (
              <>
                <span className="font-bold">
                  {t("modals.cancelOrderConfirmation.pleaseWait")}
                </span>
                <span className="loading loading-spinner loading-md" />
              </>
            )}
          </button>

          <button
            onClick={onClose}
            className="btn-block text-neutral-900 text-base font-semibold font-['Figtree', 'IRANYekanXVF'] leading-normal"
          >
            Discard
          </button>
        </div>
      </div>
    );
  };

  if (uaResults.mobile) {
    return (
      <Sheet
        isOpen={visible}
        onClose={onClose}
        detent="content-height"
        tweenConfig={{ ease: "easeIn", duration: 0.3 }}
        style={{ zIndex: 50 }}
      >
        <Sheet.Container className="!rounded-t-3xl !bg-base-100">
          <Sheet.Header />
          <Sheet.Scroller>
            <Sheet.Content>
              <Content />
            </Sheet.Content>
          </Sheet.Scroller>
        </Sheet.Container>
        <Sheet.Backdrop className="backdrop-blur-sm" onTap={onClose} />
      </Sheet>
    );
  }
  return (
    <Modal
      isOpen={visible}
      onClose={onClose}
      classNames={{ dialog: "max-w-md" }}
    >
      <Content />
    </Modal>
  );
}

export default cancelOrderConfirmation;
