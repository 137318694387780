import { UAContext } from "@quentin-sommer/react-useragent";
import classnames from "classnames";
import flag from "country-code-emoji";
import dayjs from "dayjs";
import httpStatus from "http-status";
import lodash from "lodash";
import { LaptopMinimal, Smartphone } from "lucide-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Sheet } from "react-modal-sheet";
import Modal from "~/components/Modal";
import to from "~/utils/to";

const renderPlatformIcon = ({ session }) => {
  if (!session.device?.version) return <LaptopMinimal size={64} />;
  if (session.device?.version === "mobile") return <Smartphone size={64} />;
};

function RevokeConfirmation({
  visible,
  onClose,
  session,
  onSubmit,
}: {
  visible: boolean;
  onClose: () => void;
  session: any;
}) {
  const [submitting, setSubmitting] = React.useState(false);
  const { uaResults } = React.useContext(UAContext);
  const { t } = useTranslation();

  const Content = () => {
    return (
      <div
        className="relative flex-col gap-4 rounded-2xl border-0 bg-base-100 md:pt-8"
        style={{
          paddingBottom: "calc(env(safe-area-inset-bottom) + 1rem)",
        }}
      >
        <div className="mb-5 flex flex-col items-center justify-center border-opacity-10 px-4 pb-4 gap-1">
          <div className="capitalize text-neutral-900 text-xl font-semibold font-['Hubot Sans'] leading-7">
            {t("modals.revokeConfirmation.title")}
          </div>
          <div className="text-center text-neutral-500 text-sm font-normal font-['Figtree'] leading-normal px-4">
            Are you sure you want to revoke this session? this will log the
            device out
          </div>
        </div>
        <div className="flex w-full flex-col items-center justify-center gap-5 rounded-xl px-4 text-center">
          <div className="relative mx-auto flex flex-col items-center justify-center gap-1">
            {renderPlatformIcon({ session })}
            <span className="text-zinc-500">
              {dayjs(session.updatedAt).format("DD MMM YYYY")}
            </span>
          </div>

          <div className="flex flex-col w-full gap-3 p-4 rounded-xl bg-neutral-100">
            <div className="flex justify-between w-full">
              <div>{t("modals.revokeConfirmation.browser")}</div>
              <div className="flex justify-end min-w-32 capitalize">
                {session.browser?.family}
              </div>
            </div>
            <div className="border-b border-base-300/60"></div>
            <div className="flex justify-between w-full">
              <div>{t("modals.revokeConfirmation.os")}</div>
              <div className="flex justify-end min-w-32 capitalize">
                {session.os?.family}
              </div>
            </div>
            <div className="border-b border-base-300/60"></div>
            <div className="flex justify-between w-full">
              <div>{t("modals.revokeConfirmation.country")}</div>
              <div className="flex justify-end min-w-32 capitalize">
                {session?.country && flag(session?.country)}
              </div>
            </div>
            <div className="border-b border-base-300/60"></div>
            <div className="flex justify-between w-full">
              <div>{t("modals.revokeConfirmation.ip")}</div>
              <div className="flex justify-end min-w-32 capitalize">
                {session?.ip}
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col gap-2">
            <button
              onClick={async () => {
                setSubmitting(true);
                const [] = await to(onSubmit());
                onClose();
                setSubmitting(false);
              }}
              disabled={submitting}
              className={classnames(
                "btn btn-block bg-red-100 rounded-full text-red-600 justify-center text-center",
              )}
            >
              {!submitting && (
                <span>{t("modals.revokeConfirmation.revoke")}</span>
              )}

              {submitting && (
                <>
                  <span className="font-bold">
                    {t("modals.revokeConfirmation.pleaseWait")}
                  </span>
                  <span className="loading loading-spinner loading-md" />
                </>
              )}
            </button>
            <button
              onClick={onClose}
              disabled={submitting}
              className={classnames(
                "btn btn-ghost justify-center text-center rounded-full",
              )}
            >
              {t("modals.revokeConfirmation.discard")}
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (uaResults.mobile) {
    return (
      <Sheet
        isOpen={visible}
        onClose={onClose}
        detent="content-height"
        tweenConfig={{ ease: "easeIn", duration: 0.3 }}
      >
        <Sheet.Container className="!rounded-t-3xl !bg-base-100">
          <Sheet.Header />
          <Sheet.Scroller>
            <Sheet.Content>
              <Content />
            </Sheet.Content>
          </Sheet.Scroller>
        </Sheet.Container>
        <Sheet.Backdrop className="backdrop-blur-sm" onTap={onClose} />
      </Sheet>
    );
  }
  return (
    <Modal
      isOpen={visible}
      onClose={onClose}
      classNames={{ dialog: "max-w-md" }}
    >
      <Content />
    </Modal>
  );
}

export default RevokeConfirmation;
