import { UAContext } from "@quentin-sommer/react-useragent";
import { CircleHelp } from "lucide-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Sheet } from "react-modal-sheet";
import Modal from "~/components/Modal";

function OperationWhyModal({
  visible,
  onClose,
  operation,
}: {
  visible: boolean;
  onClose: () => void;
  operation: any;
}) {
  const { uaResults } = React.useContext(UAContext);
  const { t, i18n } = useTranslation();

  const Content = () => {
    return (
      <div
        className="relative flex flex-col gap-6 rounded-2xl border-0 bg-base-100 pt-4 md:pt-8 px-4"
        style={{
          paddingBottom: "calc(env(safe-area-inset-bottom) + 1.5rem)",
        }}
      >
        <div className="gap-3 flex flex-col items-center justify-center px-4">
          <CircleHelp size={48} className="text-amber-500" />
          <div className="text-neutral-900 text-xl font-semibold font-['Hubot-Sans', 'IRANYekanXVF'] leading-7 text-center">
            {operation.number && operation.status === "system_refunded"
              ? t(
                  `modals.operationWhy.whyNumberIsRefunded.timeout_system_refunded`,
                )
              : t(
                  `modals.operationWhy.whyNumberIsRefunded.${operation.status}`,
                )}
          </div>
        </div>
        <div className="flex w-full flex-col items-center justify-center gap-5 rounded-xl px-4 text-center">
          <p className="text-center">
            {operation.number && operation.status === "system_refunded"
              ? t(`modals.operationWhy.timeout_system_refunded`)
              : t(`modals.operationWhy.${operation.status}`)}
          </p>
        </div>

        <button
          className="mx-auto w-full px-6 py-3 bg-[#a8efc0] rounded-full justify-center items-center gap-2 flex"
          onClick={onClose}
        >
          <div className="text-right text-neutral-900 text-base font-semibold font-['Figtree', 'IRANYekanXVF'] leading-normal">
            {t("okay")}
          </div>
        </button>
      </div>
    );
  };

  if (uaResults.mobile) {
    return (
      <Sheet
        isOpen={visible}
        onClose={onClose}
        detent="content-height"
        tweenConfig={{ ease: "easeIn", duration: 0.3 }}
      >
        <Sheet.Container className="!rounded-t-3xl !bg-base-100">
          <Sheet.Header />
          <Sheet.Scroller>
            <Sheet.Content>
              <Content />
            </Sheet.Content>
          </Sheet.Scroller>
        </Sheet.Container>
        <Sheet.Backdrop className="backdrop-blur-sm" onTap={onClose} />
      </Sheet>
    );
  }
  return (
    <Modal
      isOpen={visible}
      onClose={onClose}
      classNames={{ dialog: "max-w-md" }}
    >
      <Content />
    </Modal>
  );
}

export default OperationWhyModal;
