import { UAContext } from "@quentin-sommer/react-useragent";
import { useNavigate } from "@remix-run/react";
import classnames from "classnames";
import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Sheet } from "react-modal-sheet";
import Modal from "~/components/Modal";
import toastWithStyle from "~/helpers/toastWithStyle";
import { deleteResource } from "~/services/api";
import to from "~/utils/to";

function DeleteUserConfirmation({
  visible,
  onClose,
  cookie,
  user,
}: {
  visible: boolean;
  onClose: () => void;
  operation: any;
}) {
  const [submitting, setSubmitting] = React.useState(false);
  const { uaResults } = React.useContext(UAContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const onSubmit = async () => {
    setSubmitting(true);

    const [err, res] = await to(
      deleteResource({
        resource: "users",

        resourceId: user._id,
        token: cookie.token,
      }),
    );
    setSubmitting(false);

    if (err) {
      return toastWithStyle({
        message: err.response?.data?.message,
        options: { type: "error" },
        uaResults,
        i18n,
      });
    }

    navigate("/login");
  };

  const Content = () => {
    return (
      <div
        className={cx(
          "no-scrollbar relative border-0 bg-base-100 overflow-y-auto",
        )}
        style={{
          paddingBottom: "calc(env(safe-area-inset-bottom) + 2rem)",
        }}
      >
        <div className="mb-5 flex flex-col justify-center items-center gap-4 px-4 pb-4 md:mt-6">
          <div className="font-semibold font-['Hubot-Sans', 'IRANYekanXVF'] text-xl">
            {t("modals.deleteUserConfirmation.deleteAccount")}
          </div>
          <div className="text-center text-neutral-500 text-sm font-normal font-['Figtree', 'IRANYekanXVF'] leading-normal">
            {t("modals.deleteUserConfirmation.areYouSure")}
          </div>
        </div>

        <div className="flex w-full flex-col items-center justify-center gap-7 rounded-xl px-4 text-center">
          <button
            onClick={async () => {
              setSubmitting(true);
              const [] = await to(onSubmit());
              onClose();
              setSubmitting(false);
            }}
            disabled={submitting}
            className={classnames(
              "btn btn-block text-red-600 bg-red-100 text-base font-semibold font-['Figtree', 'IRANYekanXVF'] leading-normal justify-center text-center rounded-full",
            )}
          >
            {!submitting && (
              <span>{t("modals.deleteUserConfirmation.delete")}</span>
            )}

            {submitting && (
              <>
                <span className="font-bold">
                  {t("modals.deleteUserConfirmation.pleaseWait")}
                </span>
                <span className="loading loading-spinner loading-md" />
              </>
            )}
          </button>

          <button
            onClick={onClose}
            className="btn-block text-neutral-900 text-base font-semibold font-['Figtree', 'IRANYekanXVF'] leading-normal"
          >
            {t("modals.deleteUserConfirmation.discard")}
          </button>
        </div>
      </div>
    );
  };

  if (uaResults.mobile) {
    return (
      <Sheet
        isOpen={visible}
        onClose={onClose}
        detent="content-height"
        tweenConfig={{ ease: "easeIn", duration: 0.3 }}
        style={{ zIndex: 50 }}
      >
        <Sheet.Container className="!rounded-t-3xl !bg-base-100">
          <Sheet.Header />
          <Sheet.Scroller>
            <Sheet.Content>
              <Content />
            </Sheet.Content>
          </Sheet.Scroller>
        </Sheet.Container>
        <Sheet.Backdrop className="backdrop-blur-sm" onTap={onClose} />
      </Sheet>
    );
  }
  return (
    <Modal
      isOpen={visible}
      onClose={onClose}
      classNames={{ dialog: "max-w-md" }}
    >
      <Content />
    </Modal>
  );
}

export default DeleteUserConfirmation;
