import * as React from "react";

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="#fff"
      d="M18.5 18h-13c-.275 0-.5.225-.5.5v1c0 .275.225.5.5.5h13c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5Zm2-10A1.5 1.5 0 0 0 19 9.5c0 .222.05.428.137.619l-2.262 1.356a.999.999 0 0 1-1.381-.362l-2.547-4.457A1.494 1.494 0 0 0 12 4a1.494 1.494 0 0 0-.947 2.656l-2.547 4.457a1 1 0 0 1-1.381.362l-2.26-1.356c.085-.188.138-.397.138-.619A1.5 1.5 0 1 0 3.5 11c.081 0 .163-.012.24-.025L6 17h12l2.26-6.025c.077.013.159.025.24.025a1.5 1.5 0 0 0 0-3Z"
    />
  </svg>
);
export default SvgComponent;
